import { useContext } from 'react';
import { FetchQueryOptions, QueryClient, QueryObserverResult, useQueries, useQuery } from 'react-query';
import { UseQueryOptions } from 'react-query/types/react/types';

import { TerminalContext } from '../TerminalInit';
import { Attribute } from './attribute';
import { ApiViewSet, DetailOptions, apiDetail, apiList } from './baseApi';
import { ApiQueryParams, queryParamsToCacheKeys } from './baseQueryParams';
import { Shop, isShop } from './shops';
import { FetchOptions } from './utils';

export interface Product {
    id: number;
    url: string;
    account: number;
    category: number;
    shops: string;
    instances: string;
    name: string;
    image: string | null;

    ean?: string[];
    sku?: string[];

    product_data: unknown;
    product_codes: string[];

    attributes: Attribute[];
    product_attributes: ProductAttribute[];

    slots: number[];

    ordering_allowed: boolean;

    weight?: number;
}

export interface ProductAttribute {
    account: number;
    product: number;
    attribute: number;
    value: string;
}

export const isProduct = (product: any): product is Product => {
    return (product as Product).url !== undefined && (product as Product).url.includes('/products/');
};

enum ProductsQueryParams {
    SHOP = 'shop'
}

const productViewSet: ApiViewSet = {
    baseName: 'products'
};

export interface ProductsOptions {
    shop?: Shop | string | number | null;
}

const defaultConfig = {
    staleTime: 1000 * 60 * 5,
    cacheTime: Infinity
};

function fetchProductsApi(queryParams?: ApiQueryParams<ProductsQueryParams> | null, fetchOptions?: FetchOptions): () => Promise<Product[]> {
    return apiList<Product, ProductsQueryParams>(productViewSet, queryParams, fetchOptions);
}

function fetchProductApi(options: DetailOptions, fetchOptions?: FetchOptions): () => Promise<Product> {
    return apiDetail<Product>(productViewSet, options, fetchOptions);
}

export async function prefetchProducts(queryClient: QueryClient, shops: Shop[], options?: FetchQueryOptions<Product[]>, fetchOptions?: FetchOptions) {
    const config = {
        ...defaultConfig,
        ...options
    };

    await Promise.all(
        shops.map((shop) => {
            return (async () => {
                await queryClient.fetchQuery(['products', shop.id.toString()], fetchProductsApi({ shop: shop.id.toString() }, fetchOptions), config);
            })();
        })
    );
}

export function useProducts(productsUrl?: string | null, options?: ProductsOptions, queryOptions?: UseQueryOptions<Product[]>, fetchOptions?: FetchOptions) {
    const config = {
        ...defaultConfig,
        ...queryOptions
    };

    const terminalContext = useContext(TerminalContext);
    fetchOptions = {
        includeAccessToken: terminalContext.includeAccessToken,
        accessToken: terminalContext.accessToken,
        ...fetchOptions
    };

    const apiOptions = {
        shop: options?.shop
    } as ApiQueryParams<ProductsQueryParams>;

    if (productsUrl) {
        try {
            const url = new URL(productsUrl);
            const urlSearchParams = new URLSearchParams(url.search);
            if (urlSearchParams.has('shop')) {
                apiOptions.shop = urlSearchParams.get('shop');
            }
        } catch (e) {}
    }

    const shopId = apiOptions.shop ? (isShop(apiOptions.shop) ? apiOptions.shop.id : apiOptions.shop).toString() : undefined;
    const queryParams: ApiQueryParams<ProductsQueryParams> = {
        shop: shopId
    };

    return useQuery<Product[]>(['products', queryParamsToCacheKeys(ProductsQueryParams, queryParams)], fetchProductsApi(queryParams, fetchOptions), config);
}

export function getProductQueryOptions<T>(
    productId?: number | string,
    options?: UseQueryOptions<Product, unknown, T>,
    fetchOptions?: FetchOptions
): UseQueryOptions<Product, unknown, T> {
    const config = {
        ...defaultConfig,
        enabled: !!productId,
        ...options
    };

    return {
        queryKey: ['product', productId],
        queryFn: fetchProductApi({ id: productId ? productId : '' }, fetchOptions),
        ...config
    };
}

export function useProduct(productId?: string | number, queryOptions?: UseQueryOptions<Product>, fetchOptions?: FetchOptions) {
    const terminalContext = useContext(TerminalContext);
    fetchOptions = {
        includeAccessToken: terminalContext.includeAccessToken,
        accessToken: terminalContext.accessToken,
        ...fetchOptions
    };

    return useQuery<Product>(getProductQueryOptions(productId, queryOptions, fetchOptions));
}

export function useProductArray(
    productIds?: Array<number | string> | null,
    options?: UseQueryOptions<Product>,
    fetchOptions?: FetchOptions
): QueryObserverResult<Product>[] {
    const terminalContext = useContext(TerminalContext);
    fetchOptions = {
        includeAccessToken: terminalContext.includeAccessToken,
        accessToken: terminalContext.accessToken,
        ...fetchOptions
    };

    return useQueries(
        productIds
            ? productIds.map((productId) => {
                  return getProductQueryOptions(productId, options, fetchOptions) as UseQueryOptions;
              })
            : []
    ) as QueryObserverResult<Product>[];
}
