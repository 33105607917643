import { isNaN } from 'lodash';
import { useContext } from 'react';
import { FetchQueryOptions, QueryClient, UseQueryResult, useMutation, useQueries, useQuery } from 'react-query';
import { UseMutationOptions, UseQueryOptions } from 'react-query/types/react/types';

import { TerminalContext } from '../TerminalInit';
import { Transaction } from '../common/transactions';
import { GetInfoSlotResult, GetOccupySlotResult } from '../terminal/TerminalInterface';
import { ApiViewSet, DetailOptions, apiDetail, apiList } from './baseApi';
import { ApiQueryParams, queryEnabled, queryParamsToCacheKeys } from './baseQueryParams';
import { Spot } from './spots';
import { ApiError, FetchOptions, postApi } from './utils';

/**
 * Type used to decide the behaviour of the slot.
 */
export enum SlotType {
    STANDARD = 'standard',
    STOCK = 'stock',
    RESERVATION = 'reservation'
}

export interface SlotSpecification {
    id: number;
    name: string;
    max_items: number;
    width: number;
    height: number;
    depth: number;
}

export interface Slot {
    id: string;
    real_id: number;
    url: string;
    slot_nr: string;
    last_door_log: SlotDoorStatus;
    last_functional_log: SlotFunctionalStatus;
    last_sensor_log: SlotSensorStatus;
    specification: SlotSpecification;
    additional_data: unknown;
    type: SlotType;

    assigned_products: number[];
    //Settings
    settings_is_active: boolean;
    settings_allow_notifications: boolean;

    //PuDo
    settings_allow_multiple_pudo_pickup_transactions: boolean;

    //Vending
    settings_allow_same_vending_products: boolean;

    //Transaction limits
    settings_transactions_limit_for_vending: number;
    settings_transactions_limit_for_pudo: number;
    settings_transactions_limit_for_lending: number;
    settings_transactions_limit_for_return: number;

    settings_dropoff_in_progress_timeout_in_sec: number;
    settings_pickup_in_progress_timeout_in_sec: number;
    settings_remove_parcel_in_progress_timeout_in_sec: number;
}

export interface SlotStatusUpdateResult {
    id: string;
    url: string;
}

export interface MutateSlotStatusVariables {
    slot_id: string;
    body: SlotStatus;
}

interface SlotStatus {
    info: {
        code: number;
        message: string;
    };
    slot: number;
    transaction: number | null;
}

interface SlotDoorStatus extends SlotStatus {
    type: 'door';
    state: SlotDoorStatusValue;
}

enum SlotDoorStatusValue {
    OPENED = 'opened',
    CLOSED = 'closed',
    UNKNOWN = 'unknown'
}

interface SlotFunctionalStatus extends SlotStatus {
    type: 'functional';
    state: SlotFunctionalStatusValue;
}

enum SlotFunctionalStatusValue {
    ACTIVE = 'active',
    NOT_ACTIVE = 'not_active',
    UNKNOWN = 'unknown'
}

interface SlotSensorStatus extends SlotStatus {
    type: 'sensor';
    state: SlotSensorStatusValue;
}

enum SlotSensorStatusValue {
    EMPTY = 'empty',
    NOT_EMPTY = 'not_empty',
    UNKNOWN = 'unknown'
}

enum SlotsQueryParams {
    SPOT = 'spot',
    STATUS = 'status',
    TRANSACTION_TYPE = 'transaction_type',
    RECEIVER = 'receiver',
    RECEIVER_GROUP = 'receiver_group'
}

const slotViewSet: ApiViewSet = {
    baseName: 'slots'
};

export interface SlotForTransaction extends Slot {
    transaction: Transaction;
}

export enum SlotQueryStatus {
    AVAILABLE = 'available'
}

export interface SlotsOptions {
    spot?: Spot | null;
    status?: SlotQueryStatus;
}

const defaultConfig = {
    staleTime: Infinity
};

function fetchSlotsApi(queryParams?: ApiQueryParams<SlotsQueryParams> | null, fetchOptions?: FetchOptions): () => Promise<Slot[]> {
    return apiList<Slot, SlotsQueryParams>(slotViewSet, queryParams, fetchOptions);
}

function fetchSlotApi(options: DetailOptions, fetchOptions?: FetchOptions): () => Promise<Slot> {
    return apiDetail(slotViewSet, options, fetchOptions);
}

export async function prefetchSlots(
    queryClient: QueryClient,
    queryParams?: ApiQueryParams<SlotsQueryParams> | null,
    queryOptions?: FetchQueryOptions<Slot[]>,
    fetchOptions?: FetchOptions
) {
    const config = {
        ...defaultConfig,
        ...queryOptions
    };

    await queryClient.fetchQuery(['slots', queryParamsToCacheKeys(SlotsQueryParams, queryParams)], fetchSlotsApi(queryParams, fetchOptions), config);
}

export function useSlots(queryParams?: ApiQueryParams<SlotsQueryParams> | null, queryOptions?: UseQueryOptions<Slot[]>, fetchOptions?: FetchOptions) {
    const config = {
        ...defaultConfig,
        enabled: queryEnabled(queryParams),
        ...queryOptions
    };
    if (queryParams?.status) {
        // If status is set, then the query should become stale immediately as the status of slots is fleeting.
        config.staleTime = 0;
        config.cacheTime = 0;
    }

    const terminalContext = useContext(TerminalContext);
    fetchOptions = {
        includeAccessToken: terminalContext.includeAccessToken,
        accessToken: terminalContext.accessToken,
        ...fetchOptions
    };

    return useQuery<Slot[]>(['slots', queryParamsToCacheKeys(SlotsQueryParams, queryParams)], fetchSlotsApi(queryParams, fetchOptions), config);
}

export function useSlotsGlobal(
    queryParams?: ApiQueryParams<SlotsQueryParams> | null,
    queryOptions?: UseQueryOptions<Slot[]>,
    fetchOptions?: FetchOptions,
    enabled?: boolean
) {
    const config = {
        ...defaultConfig,
        enabled: enabled,
        ...queryOptions
    };
    if (queryParams?.status) {
        // If status is set, then the query should become stale immediately as the status of slots is fleeting.
        config.staleTime = 0;
        config.cacheTime = 0;
    }

    const terminalContext = useContext(TerminalContext);
    fetchOptions = {
        includeAccessToken: terminalContext.includeAccessToken,
        accessToken: terminalContext.accessToken,
        ...fetchOptions
    };

    return useQuery<Slot[]>(['slots-global', queryParamsToCacheKeys(SlotsQueryParams, queryParams)], fetchSlotsApi(queryParams, fetchOptions), config);
}

export const useSlotsForTransactions = (
    transactions?: Transaction[] | null,
    queryOptions?: UseQueryOptions<Slot, unknown, SlotForTransaction>,
    fetchOptions?: FetchOptions
): UseQueryResult<SlotForTransaction>[] => {
    const terminalContext = useContext(TerminalContext);
    fetchOptions = {
        includeAccessToken: terminalContext.includeAccessToken,
        accessToken: terminalContext.accessToken,
        ...fetchOptions
    };

    const transactionsNormalized = transactions ? transactions : [];
    return useQueries(
        transactionsNormalized.map((transaction) => {
            return getSlotQueryOptions<SlotForTransaction>(
                transaction.slot_id,
                {
                    select: (data: Slot): SlotForTransaction => {
                        return {
                            transaction: transaction,
                            ...data
                        };
                    },
                    ...queryOptions
                },
                fetchOptions
            ) as UseQueryOptions;
        })
    ) as UseQueryResult<SlotForTransaction>[];
};

export function getSlotQueryOptions<T>(
    slotId?: string,
    options?: UseQueryOptions<Slot, unknown, T>,
    fetchOptions?: FetchOptions
): UseQueryOptions<Slot, unknown, T> {
    const config = {
        ...defaultConfig,
        enabled: !!slotId,
        ...options
    };

    return {
        queryKey: ['slot', slotId],
        queryFn: fetchSlotApi({ id: slotId ? slotId : '' }, fetchOptions),
        ...config
    };
}

export function useSlot(slotId?: string, options?: UseQueryOptions<Slot>, fetchOptions?: FetchOptions) {
    const terminalContext = useContext(TerminalContext);
    fetchOptions = {
        includeAccessToken: terminalContext.includeAccessToken,
        accessToken: terminalContext.accessToken,
        ...fetchOptions
    };

    return useQuery<Slot>(getSlotQueryOptions(slotId, options, fetchOptions));
}

export function updateSlotStatus(options?: FetchOptions): (variables: MutateSlotStatusVariables) => Promise<Slot> {
    return async (variables: MutateSlotStatusVariables): Promise<Slot> => {
        const response = await postApi(`/slots/${variables.slot_id}/state/`, variables.body, options);
        if (!response.ok) {
            let json;
            try {
                json = await response.json();
            } catch (e) {
                throw new ApiError('Error updating slot status');
            }
            throw new ApiError('Error updating slot status', json);
        }
        return await response.json();
    };
}

export function useMutateUpdateSlotStatus(
    options?: UseMutationOptions<SlotStatusUpdateResult, unknown, MutateSlotStatusVariables>,
    fetchOptions?: FetchOptions
) {
    const config: UseMutationOptions<SlotStatusUpdateResult, unknown, MutateSlotStatusVariables> = {
        ...options
    };

    const terminalContext = useContext(TerminalContext);
    fetchOptions = {
        includeAccessToken: terminalContext.includeAccessToken,
        accessToken: terminalContext.accessToken,
        ...fetchOptions
    };

    return useMutation(updateSlotStatus(fetchOptions), config);
}

export function getRealIdFromId(id: string): number {
    while (id[0] !== undefined && (isNaN(id[0]) || id[0] !== '0')) {
        id = id.slice(1);
    }
    if (id[0] === undefined) return 0;
    return parseInt(id);
}

export function getIdFromRealId(id: number | string): string {
    if (typeof id == 'string' && id.includes('SLT')) return id;
    return `SlT${'0'.repeat(6 - JSON.stringify(id).length)}${id}`;
}

export const getSlotStatusVariables = (
    result: GetOccupySlotResult | GetInfoSlotResult,
    slot: Slot | number | string,
    transaction: Transaction | undefined | null
): MutateSlotStatusVariables => {
    const variables: MutateSlotStatusVariables = {
        slot_id: typeof slot == 'number' || typeof slot == 'string' ? getIdFromRealId(slot) : slot.id,
        body: {
            info: {
                code: result.result_code,
                message: result.error_msg
            },
            slot: typeof slot == 'number' ? slot : typeof slot == 'string' ? +slot.replace('SLT', '') : slot.real_id,
            transaction: transaction ? transaction.real_id : null
        }
    };

    if ('is_occupied' in result) {
        result = result as GetOccupySlotResult;
        variables.body = {
            type: 'sensor',
            state: result.is_occupied ? SlotSensorStatusValue.NOT_EMPTY : SlotSensorStatusValue.EMPTY,
            ...variables.body
        } as SlotSensorStatus;
    }
    if ('is_opened' in result) {
        result = result as GetInfoSlotResult;
        variables.body = {
            type: 'door',
            state: result.is_opened ? SlotDoorStatusValue.OPENED : SlotDoorStatusValue.CLOSED,
            ...variables.body
        } as SlotDoorStatus;
    }
    return variables;
};

export function getTransactionsFromSlot(transactionSlotMap: Map<string, SlotForTransaction[]>, item: Slot): Transaction[] {
    const slotForTransaction = transactionSlotMap.get(item.id);
    const result: Transaction[] = [];
    slotForTransaction?.forEach((slot) => {
        slot.transaction && result.push(slot.transaction);
    });
    return result;
}

export function getColumnNumber(slot: Slot): string {
    return slot.slot_nr.split('-')[1];
}
export function getRowNumber(slot: Slot): string {
    return slot.slot_nr.split('-')[0];
}
