import { useContext } from 'react';
import { FetchQueryOptions, QueryClient, UseMutationOptions, UseQueryOptions, useMutation, useQuery, useQueryClient } from 'react-query';

import { TerminalContext } from '../TerminalInit';
import { TransactionStatus } from '../common/transactions';
import { ApiViewSet, DetailOptions, apiDetail, apiList } from './baseApi';
import { ApiQueryParams, queryEnabled, queryParamsToCacheKeys } from './baseQueryParams';
import { Slot, SlotForTransaction } from './slots';
import { ApiError, FetchOptions, postApi, putApi } from './utils';

export interface StockFill {
    id: number;
    url: string;
    report_code: string;
    account: number;
    spot: string;
    spot_url: string;
    delivery_contact_group: string;
    is_completed: boolean;
    created_by: string;
    completed_by: number;
    created_at: Date;
    updated_at: Date;
}

const defaultConfig = {
    staleTime: 1000 * 60 * 5,
    cacheTime: Infinity
};

const stockFillViewSet: ApiViewSet = {
    baseName: 'stock-fills'
};

interface MutateUpdateStockFillVariables {
    stockFillId: string;
}

interface MutateConfirmStockFillVariables {
    report_code: string;
    updateVariables: {
        account: number;
        completed_by: number;
    };
}

enum StockFillQueryParams {
    CATEGORY = 'category',
    NAME = 'name',
    VALUES = 'values',
    ACCOUNT = 'account'
}

export const isStockFill = (stockFill: any | unknown): stockFill is StockFill => {
    return (
        (stockFill as StockFill).url !== undefined &&
        (stockFill as StockFill).url.includes('/stock-fills/') &&
        isValidReportCode((stockFill as StockFill).report_code)
    );
};

export function parseToValidReportCode(report_code: string): string {
    let result = 'SFR';
    for (let i = 0; i < 6 - report_code.length; i++) {
        result += '0';
    }
    return result + report_code;
}

export function isValidReportCode(report_code: string): boolean {
    return report_code.startsWith('SFR') && report_code.length === 9;
}

//GET
function fetchStockFillApi(options: DetailOptions, fetchOptions?: FetchOptions): () => Promise<StockFill> {
    return apiDetail<StockFill>(stockFillViewSet, options, fetchOptions);
}

export function useStockFill(
    stockFillReportNumber?: string,
    stockFillId?: string,
    stockFillUrl?: string,
    queryOptions?: UseQueryOptions<StockFill>,
    fetchOptions?: FetchOptions
) {
    const config = {
        ...defaultConfig,
        enabled: !!stockFillUrl || !!stockFillId || !!stockFillReportNumber,
        ...queryOptions
    };

    const terminalContext = useContext(TerminalContext);
    fetchOptions = {
        includeAccessToken: terminalContext.includeAccessToken,
        accessToken: terminalContext.accessToken,
        ...fetchOptions
    };

    let detailOptions: DetailOptions;
    if (stockFillReportNumber) {
        detailOptions = {
            url: stockFillReportNumber
        };
    } else if (stockFillUrl) {
        detailOptions = {
            url: stockFillUrl
        };
    } else if (stockFillId) {
        detailOptions = {
            id: stockFillId
        };
    } else {
        detailOptions = {
            id: ''
        };
    }

    return useQuery<StockFill>(['stock-fills', stockFillUrl || stockFillId || stockFillReportNumber], fetchStockFillApi(detailOptions, fetchOptions), config);
}

//GET Multiple
function fetchStockFillsApi(queryParams?: ApiQueryParams<StockFillQueryParams> | null, fetchOptions?: FetchOptions): () => Promise<StockFill[]> {
    return apiList<StockFill, StockFillQueryParams>(stockFillViewSet, queryParams, fetchOptions);
}

function getStockFillUseQueryOptions(
    queryClient: QueryClient,
    queryParams?: ApiQueryParams<StockFillQueryParams> | null,
    queryOptions?: UseQueryOptions<StockFill[]>,
    fetchOptions?: FetchOptions
): UseQueryOptions<StockFill[]> {
    const config = {
        ...defaultConfig,
        enabled: queryEnabled(queryParams),
        onSuccess: (data: StockFill[]) => {
            for (const stockFill of data) {
                queryClient.setQueryData(['stock-fills', stockFill.id], stockFill);
                queryClient.setQueryData(['stock-fills', stockFill.report_code], stockFill);
                queryClient.setQueryData(['stock-fills', stockFill.url], stockFill);
            }
        },
        ...queryOptions
    };

    return {
        queryKey: ['stock-fills', queryParamsToCacheKeys<StockFillQueryParams>(StockFillQueryParams, queryParams)],
        queryFn: fetchStockFillsApi(queryParams, fetchOptions),
        ...config
    };
}

export async function fetchStockFills(
    queryClient: QueryClient,
    queryParams?: ApiQueryParams<StockFillQueryParams> | null,
    queryOptions?: FetchQueryOptions<StockFill[]>,
    fetchOptions?: FetchOptions
): Promise<StockFill[]> {
    if (queryParams?.account) queryParams.account = JSON.stringify(queryParams.account);
    return await queryClient.fetchQuery(getStockFillUseQueryOptions(queryClient, queryParams, queryOptions, fetchOptions));
}

export function useStockFills(
    queryParams?: ApiQueryParams<StockFillQueryParams> | null,
    queryOptions?: UseQueryOptions<StockFill[]>,
    fetchOptions?: FetchOptions
) {
    const queryClient = useQueryClient();

    const terminalContext = useContext(TerminalContext);
    fetchOptions = {
        includeAccessToken: terminalContext.includeAccessToken,
        accessToken: terminalContext.accessToken,
        ...fetchOptions
    };

    if (queryParams?.account) queryParams.account = JSON.stringify(queryParams.account);
    return useQuery<StockFill[]>(getStockFillUseQueryOptions(queryClient, queryParams, queryOptions, fetchOptions));
}

//UPDATE
export function updateStockFill(options?: FetchOptions): (variables: MutateUpdateStockFillVariables) => Promise<StockFill> {
    return async (variables: MutateUpdateStockFillVariables): Promise<StockFill> => {
        const response = await putApi(`/stock-fills/${variables.stockFillId}/`, options);
        if (!response.ok) {
            let json;
            try {
                json = await response.json();
            } catch (e) {
                throw new ApiError('Error updating stock fill');
            }
            throw new ApiError('Error updating stock fill', json);
        }
        return await response.json();
    };
}

export function useMutateUpdateStockFill(options?: UseMutationOptions<StockFill, unknown, MutateUpdateStockFillVariables>, fetchOptions?: FetchOptions) {
    const queryClient = useQueryClient();

    const config: UseMutationOptions<StockFill, unknown, MutateUpdateStockFillVariables> = {
        ...options,
        onSuccess: async (data, variables, context) => {
            await queryClient.setQueryData(['stock-fills', data.id], data);
            if (options?.onSuccess) {
                await options.onSuccess(data, variables, context);
            }
        },
        onSettled: async (data, error, variables) => {
            await queryClient.invalidateQueries(['stock-fills', variables.stockFillId]);
            await queryClient.invalidateQueries(['stock-fills']);
        }
    };

    const terminalContext = useContext(TerminalContext);
    fetchOptions = {
        includeAccessToken: terminalContext.includeAccessToken,
        accessToken: terminalContext.accessToken,
        ...fetchOptions
    };

    return useMutation(updateStockFill(fetchOptions), config);
}

//POST (confirm report)
export function confirmStockFillReport(options?: FetchOptions): (variables: MutateConfirmStockFillVariables) => Promise<StockFill> {
    return async (variables: MutateConfirmStockFillVariables): Promise<StockFill> => {
        const response = await postApi(`/stock-fills/${variables.report_code}/confirm_report/`, variables.updateVariables, options);
        if (!response.ok) {
            let json;
            try {
                json = await response.json();
            } catch (e) {
                throw new ApiError('Error updating stock fill status');
            }
            throw new ApiError('Error updating stock fill status', json);
        }
        return await response.json();
    };
}

export function useMutateConfirmStockFillReport(
    options?: UseMutationOptions<StockFill, unknown, MutateConfirmStockFillVariables>,
    fetchOptions?: FetchOptions
) {
    const queryClient = useQueryClient();

    const config: UseMutationOptions<StockFill, unknown, MutateConfirmStockFillVariables> = {
        ...options,
        onSuccess: async (data, variables, context) => {
            await queryClient.setQueryData(['stock-fills', data.id], data);
            if (options?.onSuccess) {
                await options.onSuccess(data, variables, context);
            }
        },
        onSettled: async (data, error, variables) => {
            await queryClient.invalidateQueries(['stock-fills']);
        }
    };

    const terminalContext = useContext(TerminalContext);
    fetchOptions = {
        includeAccessToken: terminalContext.includeAccessToken,
        accessToken: terminalContext.accessToken,
        ...fetchOptions
    };

    return useMutation(confirmStockFillReport(fetchOptions), config);
}

export function getSlotsWithValidTransactions(transactionSlotMap: Map<string, SlotForTransaction[]> | undefined): Slot[] {
    const result: Slot[] = [];
    transactionSlotMap?.forEach((val, key) => {
        val.every((tr) => {
            if (TransactionStatus.before_dropoff_states().includes(tr.transaction.status)) {
                result.push(tr);
                return false;
            } else return true;
        });
    });
    return result;
}

export function getValidSlotsIncludedInSFR(transactionSlotMap: Map<string, SlotForTransaction[]> | undefined, sfrNumber: number): Slot[] {
    const result: Slot[] = [];
    transactionSlotMap?.forEach((val, key) => {
        let validTrs = [];
        validTrs = val.filter((tr) => TransactionStatus.before_dropoff_states().includes(tr.transaction.status));
        validTrs.every((tr) => {
            if (tr.transaction.stock_fill_product && tr.transaction.stock_fill_product.stock_fill_id === sfrNumber) {
                result.push(tr);
                return false;
            } else return true;
        });
    });
    return result;
}
