import { useContext, useEffect, useMemo, useState } from 'react';
import { FaFileExport } from 'react-icons/fa';
import { FormattedMessage, useIntl } from 'react-intl';

import { TerminalContext, TerminalContextInterface } from '../../../../TerminalInit';
import { useProducts } from '../../../../api/products';
import { useShops } from '../../../../api/shops';
import { Slot, getColumnNumber, getRowNumber, useSlots } from '../../../../api/slots';
import { SpotLayoutItem, getSlotFromSpotLayoutItem, getTransactionsFromSpotlayoutItem, useSpotLayoutItems } from '../../../../api/spotLayoutItems';
import { useSpotLayout } from '../../../../api/spotLayouts';
import { useSpot } from '../../../../api/spots';
import { StockFill, getValidSlotsIncludedInSFR, useStockFills } from '../../../../api/stockFills';
import { TerminalWorkflowType } from '../../../../api/terminals';
import { TransactionStatus } from '../../../../common/transactions';
import ActionCard from '../../../../components/domain/ActionCard';
import { AllowedActionViewProps } from '../../../../components/domain/AllowedAction';
import useActivity from '../../../../hooks/useActivity';
import useTransactionsWithSlots from '../../../../hooks/useTransactionsWithSlots';
import { isDelivery } from '../../../../services/contact/GlobalPermissions';
import BackAndHomeNavigationButtons from '../../../../views/common/BackAndHomeNavigationButtons';
import BaseView from '../../../../views/common/BaseView';
import LoadingView from '../../../../views/common/LoadingView';
import LargeMultiSpotLayout from '../../../../views/spotlayout/LargeMultiSpotLayout';
import MultiSpotLayout from '../../../../views/spotlayout/MultiSpotLayout';
import SelectStockFillReport from '../../../../views/vending/SelectStockFillReport';
import ManualStockRefillTransactionUpdate from '../../../vending/management/ManualStockRefillTransactionUpdate.tsx';

interface ManualStockRefillActionProps extends AllowedActionViewProps {
    skipReportNumber?: boolean;
}
//instead of returning the transaction array always just return a slot array so transactions can be created/updated somewhere else !!!!!
export const ManualStockRefillWorkflow = (props: ManualStockRefillActionProps) => {
    const [, newActivity] = useActivity();

    const { terminal } = useContext<TerminalContextInterface>(TerminalContext);
    const { data: spotLayout } = useSpotLayout(terminal?.spot_layout_url);
    const { data: spotLayoutItems } = useSpotLayoutItems({ spotLayout: spotLayout }, { enabled: !!spotLayout });

    const { data: shops } = useShops({ terminal: terminal });
    const allProducts = useProducts(shops && shops.length > 0 ? shops[0].products : undefined, undefined);
    const { data: spot } = useSpot(spotLayout?.spot_url);
    const { data: slots } = useSlots({ spot: spot?.id });
    const { data: transactionSlotMap } = useTransactionsWithSlots({
        spotId: spot?.id
    });
    const {
        data: stockFillReports,
        isLoading: sfrLoading,
        isSuccess: sfrSuccess
    } = useStockFills({
        account: JSON.stringify(spot?.account)
    });

    const [spotSlotMap, changeSpotSlotMap] = useState<Map<SpotLayoutItem, Slot>>(new Map());

    const [title, changeTitle] = useState<JSX.Element>();
    const [report, changeReport] = useState<StockFill | undefined>(undefined);
    const [reportNumberPageActive, changeReportNumberPageActive] = useState<boolean>(props.skipReportNumber !== undefined ? !props.skipReportNumber : true);
    const [rows, changeRows] = useState<Map<string, SpotLayoutItem[]>>(new Map());

    //all possible submitted items
    const [selectedSlots, changeSelectedSlots] = useState<Slot[] | undefined>();
    useEffect(() => {
        if (!props.skipReportNumber && report !== undefined) {
            changeSelectedSlots(getValidSlotsIncludedInSFR(transactionSlotMap, report?.id));
        }
    }, [report, props.skipReportNumber]);

    const [editSelection, changeEditSelection] = useState<boolean>(props.skipReportNumber ? true : false);

    useEffect(() => {
        if (slots && spotLayoutItems) {
            const newMap = new Map();
            spotLayoutItems.forEach((item) => {
                const foundSlot = slots.find((s) => s.id === item.slot);
                if (foundSlot !== undefined) newMap.set(item, foundSlot);
            });
            changeSpotSlotMap(newMap);
        }
    }, [slots, spotLayoutItems]);

    const onBack = () => {
        newActivity();
        if (!reportNumberPageActive && !props.skipReportNumber) {
            if (editSelection) {
                changeEditSelection(false);
            } else changeReportNumberPageActive(true);
        } else {
            if (props.onHome) props.onHome();
        }
    };

    const backHomeNav = (
        <BackAndHomeNavigationButtons
            onHome={props.onHome}
            onBack={onBack}
        />
    );

    const clickableSpotlayoutItem = (item: SpotLayoutItem, isAlreadySelected: boolean): boolean => {
        if (isAlreadySelected) return true; //User can always deselect
        const slot = spotSlotMap.get(item);
        if (slot !== undefined) {
            const prod = allProducts.data?.find((p) => p.slots.includes(slot!.real_id));
            if (prod === undefined) {
                return false;
            }
        }
        if (transactionSlotMap) {
            const relevantTransactions = getTransactionsFromSpotlayoutItem(transactionSlotMap, item);

            //With Report
            if (report !== undefined) {
                const isValid = relevantTransactions.find((tr) => {
                    return getValidSlotsIncludedInSFR(transactionSlotMap, report.id).find((s) => s.id === tr.id);
                });
                if (isValid !== undefined) return true;
            }

            //Without Report or if the previeus failed with a report
            //if there is space for a new transaction or there is a transaction that can be updated to dropoff done it should be valid
            let valid = false;

            valid = relevantTransactions.length === 0 || spotSlotMap.get(item)?.settings_transactions_limit_for_vending === undefined;
            if (
                !valid &&
                spotSlotMap.get(item) &&
                spotSlotMap.get(item)!.settings_transactions_limit_for_vending &&
                spotSlotMap.get(item)!.settings_transactions_limit_for_vending <= relevantTransactions.length
            ) {
                const foundTransaction = relevantTransactions.find((tr) => TransactionStatus.before_dropoff_states().includes(tr.status));
                valid = foundTransaction !== undefined;
            }

            if (!valid) {
                relevantTransactions?.forEach((tr) => {
                    if (TransactionStatus.before_dropoff_states().includes(tr.status)) valid = true;
                });
            }
            return valid;
        }
        return false;
    };

    const calculateRows = useMemo(
        () => (): Map<string, SpotLayoutItem[]> => {
            let rows: Map<string, SpotLayoutItem[]> = new Map();
            spotLayoutItems!.forEach((spot) => {
                if (!spot.terminal) {
                    const slot = spotSlotMap.get(spot);
                    if (slot) {
                        const existingRow = rows.get(getRowNumber(slot));
                        if (existingRow) {
                            existingRow.push(spot);
                            rows.set(
                                getRowNumber(slot),
                                existingRow.sort((a, b) => sortBySlot(a, b, spotSlotMap))
                            );
                        } else {
                            rows.set(getRowNumber(slot), [spot]);
                        }
                    }
                }
            });
            rows = new Map([...rows.entries()].sort());
            return rows;
        },
        [spotLayoutItems, spotSlotMap]
    );

    const submitSelectedItems = (items: SpotLayoutItem[]) => {
        changeEditSelection(false);
        changeSelectedSlots(items.map((item) => getSlotFromSpotLayoutItem(slots!, item)));
    };

    useEffect(() => {
        if (spotLayoutItems) changeRows(calculateRows());
    }, [calculateRows, spotLayoutItems]);

    useEffect(() => {
        if (report !== undefined) {
            changeTitle(
                <FormattedMessage
                    id='workflows.ManualStockRefill.ExtraFilledSlots.Title'
                    description='The title for the select slots screen during manual stockrefill.'
                    defaultMessage='You can edit the SLOTs that will be updated here.'
                />
            );
        } else {
            changeTitle(
                <FormattedMessage
                    id='workflows.ManualStockRefill.FilledSlots.Title'
                    description='The title for the select slots screen during manual stockrefill without report.'
                    defaultMessage='Please select all SLOTs that were filled.'
                />
            );
        }
    }, [report]);
    if ((sfrLoading === true || sfrSuccess === false) && reportNumberPageActive) {
        return <LoadingView></LoadingView>;
    } else if (
        !reportNumberPageActive &&
        (spot === undefined || spotSlotMap === new Map() || rows === new Map() || spotLayoutItems === undefined || transactionSlotMap === undefined)
    ) {
        return <LoadingView></LoadingView>;
    } else if (reportNumberPageActive) {
        return (
            <SelectStockFillReport
                allReports={stockFillReports!.filter((re) => re.spot !== spot?.id).filter((re) => !re.is_completed)}
                saveReportNumber={(value) => {
                    changeReport(value);
                    newActivity();
                    changeReportNumberPageActive(false);
                }}
                onHome={props.onHome}
                onBack={props.onBack}
                onInactivity={props.onInactivity}
            />
        );
    }

    if (!props.skipReportNumber && selectedSlots === undefined) {
        return <LoadingView></LoadingView>;
    }
    if (editSelection) {
        //updating the layout where transactions should be created
        const row = rows.get([...rows.keys()][0]);
        if ([...rows.keys()].length > 10 || (row !== undefined && row!.length > 15)) {
            return (
                <BaseView
                    navbarItems={backHomeNav}
                    onInactivity={props.onInactivity}>
                    <h1 className='title text-center'>{title}</h1>
                    <LargeMultiSpotLayout
                        spotSlotMap={spotSlotMap}
                        clickableSpotlayoutItem={clickableSpotlayoutItem}
                        spotLayout={rows}
                        alreadySelected={selectedSlots}
                        onSubmit={submitSelectedItems}
                        onCancel={onBack}
                    />
                </BaseView>
            );
        }
        return (
            <BaseView
                navbarItems={backHomeNav}
                onInactivity={props.onInactivity}>
                <h1 className='title text-center'>{title}</h1>
                <MultiSpotLayout
                    spotSlotMap={spotSlotMap}
                    clickableSpotlayoutItem={clickableSpotlayoutItem}
                    spotLayout={rows}
                    onSubmit={submitSelectedItems}
                    alreadySelected={selectedSlots}
                    onCancel={onBack}
                />
            </BaseView>
        );
    } else {
        return (
            <ManualStockRefillTransactionUpdate
                spotSlotMap={spotSlotMap}
                report={report}
                selectedSlots={selectedSlots}
                contact={props.contact}
                transactionSlotMap={transactionSlotMap!}
                editSelection={() => {
                    changeEditSelection(!editSelection);
                }}
                onHome={props.onHome}
                onBack={props.onBack}
                onInactivity={props.onInactivity}
            />
        );
    }
};

function ManualStockRefillAction(props: ManualStockRefillActionProps) {
    const intl = useIntl();
    const { terminal } = useContext<TerminalContextInterface>(TerminalContext);
    if (terminal?.workflow !== TerminalWorkflowType.VENDING) return undefined;
    if (props.contact === undefined) return undefined;
    if (!isDelivery(props.contact)) return undefined;

    const ManualStockRefillAction: ActionCard = {
        actionText: intl.formatMessage({
            id: 'workflow.vending.management.ManualStockRefillAction',
            description: 'This is the action button for the ManualStockRefill',
            defaultMessage: 'Fill with Stock Fill Report'
        }),
        icon: (
            <FaFileExport
                className='dashboard-action-icon'
                size={264}
            />
        ),
        view: <ManualStockRefillWorkflow {...props} />
    };

    return ManualStockRefillAction;
}

export default ManualStockRefillAction;

function sortBySlot(spot1: SpotLayoutItem, spot2: SpotLayoutItem, spotSlotMap: Map<SpotLayoutItem, Slot>): number {
    const slot1 = spotSlotMap.get(spot1);
    const slot2 = spotSlotMap.get(spot2);
    if (slot1 && slot2) return parseFloat(getColumnNumber(slot1)) - parseFloat(getColumnNumber(slot2));
    return 0;
}
