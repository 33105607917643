import { useContext, useEffect, useState } from 'react';
import { Spinner } from 'react-bootstrap';

import Connection from '../../Connection';
import { TerminalContext, TerminalContextInterface } from '../../TerminalInit';
import { Contact } from '../../api/contacts';
import { Product, useProducts } from '../../api/products';
import { useShops } from '../../api/shops';
import { PlatformHardwareDriver, SpotLayoutItem, useSpotLayoutItems } from '../../api/spotLayoutItems';
import { useSpotLayout } from '../../api/spotLayouts';
import { Spot, useSpot } from '../../api/spots';
import ActionCard from '../../components/domain/ActionCard';
import { LoginEvent } from '../../events/LoginEvent';
import { useAppDispatch } from '../../hooks/redux';
import useActivity from '../../hooks/useActivity';
import { Logger } from '../../logs/Logger';
import { initDropoff } from '../../redux/ScalesWeightSlice';
import ScaleOverMaxWeightWarnings from '../../scales/ScaleOverMaxWeightWarnings';
import { ProtonPacketUtils } from '../../scales/proton/ProtonPacketUtils';
import ProtonScalesService from '../../scales/services/ProtonScalesService';
import { isDelivery } from '../../services/contact/GlobalPermissions';
import { ADAMHardwareObject, checkADAMDriverOptions } from '../../services/platformHardwareDriver/utils/ADAMHardwareOptions';
import { HardwareConnectionContext } from '../../terminal/useHardwareConnection';
import LockoutView from '../../views/LockoutView';
import useWebsocketContactAuthentication from '../../websocket/commands/WebsocketAuthenticateContactCommand';
import AuthenticatedContactWorkflow from '../AuthenticatedContact/AuthenticatedContactWorkflow';
import ScannerLookupModal, { ScannerLookupCallbackResult, ScannerLookupType } from '../lookup/ScannerLookupModal';
import ExitModal from './ExitModal';
import useScalesWeightUpdates from './useScalesWeightUpdates';
import useUpdateDiffIfIdle from './useUpdateDiffIfIdle';

export default function WarehouseWorkflow() {
    const [, newActivity] = useActivity();
    const dispatch = useAppDispatch();
    const [customView, changeCustomView] = useState<ActionCard | undefined>();
    const { terminal } = useContext<TerminalContextInterface>(TerminalContext);
    const { data: spotLayout } = useSpotLayout(terminal?.spot_layout_url);
    const { data: spotLayoutItems } = useSpotLayoutItems({ spotLayout: spotLayout });
    const { data: spot } = useSpot(spotLayout?.spot_url);
    const { data: shops } = useShops({ terminal: terminal }, { enabled: !!terminal });
    const { data: products } = useProducts(shops && shops.length > 0 ? shops[0].products : undefined, undefined);
    const [scalesService, changeScalesService] = useState<ProtonScalesService>();
    const [exitModal, changeExitModal] = useState<boolean>(false);

    const connection = useContext(HardwareConnectionContext)!;
    useEffect(() => {
        if (!scalesService && connection && products) {
            changeScalesService(new ProtonScalesService(connection));
        }
    }, [connection, products]);
    useEffect(() => {
        if (scalesService) {
            initialiseScales(scalesService, spotLayoutItems, products);
        }
    }, [scalesService]);

    const loadView = (view: ActionCard) => {
        newActivity();
        changeCustomView(view);
    };

    const [expectedContacts, changeExpectedContacts] = useState<Contact[]>([]);
    useWebsocketContactAuthentication({
        callback: (contact) => {
            changeExpectedContacts([...expectedContacts, contact]);
            new LoginEvent(contact).dispatch();
        }
    });
    useUpdateDiffIfIdle({ contacts: expectedContacts, spot, spotLayoutItems });

    useScalesWeightUpdates({ contacts: expectedContacts });

    const [doLookupReset, changeDoLookupReset] = useState<boolean>(true);
    const [selectedContact, changeSelectedContact] = useState<undefined | Contact>(undefined);

    const scannerCallback = (result: ScannerLookupCallbackResult) => {
        if (result.contact) {
            Logger.log('Contact authentication received via scanner.', { contact: result.contact.id }, result);
            if (isDelivery(result.contact)) {
                dispatch(initDropoff(true));
                Logger.log('Contact is delivery thus enableing dropoff.');
            }
            new LoginEvent(result.contact).dispatch();
            changeSelectedContact(result.contact);
            changeExpectedContacts([...expectedContacts, result.contact]);
            presenceIndicatorToggle(connection, spot!, true);
        }
        changeDoLookupReset(!doLookupReset);
    };

    const doExit = () => {
        newActivity();
        if (selectedContact) changeExpectedContacts(expectedContacts.filter((c) => c.id !== selectedContact.id));
        dispatch(initDropoff(false));
        changeExitModal(false);
        changeCustomView(undefined);
        changeSelectedContact(undefined);
        presenceIndicatorToggle(connection, spot!, false);
    };

    let content = (
        <div className='d-flex flex-column align-items-center'>
            <Spinner
                animation='border'
                role='status'
            />
        </div>
    );

    if (spot !== undefined) {
        if (customView !== undefined && customView.view !== undefined) {
            content = customView.view;
        } else {
            content = (
                <AuthenticatedContactWorkflow
                    contact={selectedContact}
                    workflow={terminal?.workflow}
                    onSelectAction={loadView}
                    onInactivity={() => {
                        newActivity();
                        doExit();
                    }}
                    onLogout={() => {
                        newActivity();
                        changeExitModal(true);
                    }}
                    onHome={() => {
                        newActivity();
                        changeCustomView(undefined);
                    }}
                />
            );
        }
    }

    return (
        <LockoutView>
            {!(selectedContact && isDelivery(selectedContact)) && <ScaleOverMaxWeightWarnings selectedContact={selectedContact} />}
            {content}
            <ScannerLookupModal
                scannerLookupTypes={[ScannerLookupType.CONTACT]}
                enabled={selectedContact === undefined && spot !== undefined}
                resultCallback={scannerCallback}
                doReset={doLookupReset}
            />
            <ExitModal
                show={exitModal}
                onExit={doExit}
                handleClose={() => changeExitModal(false)}
                selectedContact={selectedContact}
            />
        </LockoutView>
    );
}

export function diffFound(difference: { [spotLayoutItemId: string]: number }) {
    return Object.entries(difference).some(([, amount]) => amount != 0);
}

export interface SpotLayoutItemProductAmount {
    product: Product;
    spotLayoutItem: SpotLayoutItem;
    amount: number;
}

export function mapToSpotLayoutItemProductAmount(
    products: Product[],
    spotLayoutItems: SpotLayoutItem[],
    difference: { [spotLayoutItemId: string]: number }
): SpotLayoutItemProductAmount[] {
    const result: SpotLayoutItemProductAmount[] = [];
    Object.entries(difference).forEach(([sptId, amount]) => {
        const spotLayoutItem = spotLayoutItems.find((s) => s.id.toString() == sptId.toString());
        if (spotLayoutItem) {
            const product = products.find((p) => p.slots.includes(+spotLayoutItem.slot!.replace('SLT', '')));
            if (product) {
                result.push({
                    product,
                    spotLayoutItem,
                    amount
                });
            }
        }
    });
    return result;
}

async function initialiseScales(scalesService?: ProtonScalesService, spotLayoutItems?: SpotLayoutItem[], products?: Product[]) {
    if (scalesService && spotLayoutItems && products) {
        Logger.log('start init scales');
        const areOk = await scalesService.healthCheck();
        if (areOk === true) {
            Logger.log('start mapping scales');
            await scalesService
                .configureAll(ProtonPacketUtils.mapToProtonDevices(spotLayoutItems))
                .then(() => Logger.log('successfully mapped scales'))
                .catch((err) => Logger.error(err));
            Logger.log('start mapping products');
            await scalesService
                .bindAllProducts(ProtonPacketUtils.mapToProtonProducts(spotLayoutItems, products))
                .then(() => Logger.log('successfully mapped products'))
                .catch((err) => Logger.error(err));
            Logger.log('init scales successfull');
        }
    }
}

function presenceIndicatorToggle(con: Connection, spot: Spot, on: boolean) {
    if (checkADAMDriverOptions(spot.presence_indicator)) {
        const obj: ADAMHardwareObject = {
            driver: PlatformHardwareDriver.ADAM6060,
            id: spot.presence_indicator.config.ip!,
            slots: [
                {
                    username: spot.presence_indicator.config.username!,
                    password: spot.presence_indicator.config.password!,
                    ip: spot.presence_indicator.config.ip!,
                    port: spot.presence_indicator.config.port ? spot.presence_indicator.config.port : '0',
                    mode: 'steady',
                    doorOpenTime: on ? 1 : 0
                }
            ]
        };
        con.openSlot([obj]);
    }
}
