import { useContext, useMemo } from 'react';

import { TerminalContext, TerminalContextInterface } from '../TerminalInit';
import { AccessControl, useAccessControls, useMutateAccessControlOpen } from '../api/IOT/accessContols';
import { Contact } from '../api/contacts';
import { getRealIdFromId } from '../api/slots';
import { useSpotLayout } from '../api/spotLayouts';
import { useSpot } from '../api/spots';
import { Logger } from '../logs/Logger';

interface RakindaProps {
    contact?: Contact;
}

export default function useOpenRakinda(props: RakindaProps) {
    const openDoorMutation = useMutateAccessControlOpen();
    const { terminal } = useContext<TerminalContextInterface>(TerminalContext);
    const { data: spotLayout } = useSpotLayout(terminal?.spot_layout_url);
    const { data: spot } = useSpot(spotLayout?.spot_url);
    const { data: accessControl } = useAccessControls(spot?.id, { enabled: !!spot });

    const openRakinda = (accessControl: AccessControl, contact?: Contact) => {
        Logger.log('Start open rakinda.');
        if (spot) {
            openDoorMutation.mutate({ accessControl: accessControl, body: { contact: contact?.id.toString(), spot: spot.id } });
            Logger.log('Open rakinda was sent.', {}, accessControl);
        } else Logger.warn('NO rakinda was found.');
    };

    const availableAccessControls = useMemo(() => {
        const result: AccessControl[] = [];
        if (accessControl) {
            accessControl.forEach((ac) => {
                const spotInfo = (ac.additional_data as any)['spots'];
                if (spotInfo == null) result.push(ac);
                else if (props.contact && spot) {
                    const info = spotInfo.find((i: any) => i.id == getRealIdFromId(spot.id));
                    const role = (props.contact.additional_data as any)['role'];
                    if (info && info.roles.includes(role)) {
                        result.push(ac);
                    }
                }
            });
        }
        return result;
    }, [accessControl, props.contact]);

    return {
        openRakinda,
        availableAccessControls
    };
}
