import { Product } from '../../api/products';
import { ProtonHardwareDriverOption } from './ProtonPacketUtils';

export default class ProtonProduct {
    clusterUri: string;
    enable: boolean;
    skuUniNo: string;
    esl: ProtonProductESL;
    weight: ProtonWeight;

    constructor(option: ProtonHardwareDriverOption, product: Product, base64Image?: string) {
        this.clusterUri = option.config.clusterURI;
        this.enable = option.config.enable;
        this.skuUniNo = product.sku && product.sku.length >= 1 ? product.sku[0] : '';
        this.esl = new ProtonProductESL(product.name + ' slot', product.sku && product.sku.length >= 1 ? product.sku[0] : '', product.name, base64Image);
        this.weight = new ProtonWeight(product.weight ? product.weight : 1);
    }
}

class ProtonProductESL {
    clusterName: string;
    weight1 = null;
    weight2 = null;
    weight3 = null;
    weight4 = null;
    count1 = null;
    count2 = null;
    count3 = null;
    count4 = null;
    skuName: string;
    skuNo: string;
    image: string | null = null;

    constructor(clusterName: string, skuNumber: string, skuName: string, image: string | undefined) {
        this.clusterName = clusterName;
        this.skuNo = skuNumber;
        this.skuName = skuName;
        this.image = image ? image : null;
    }
}

class ProtonWeight {
    skuApw: number;
    skuTolerance: number;

    constructor(weight: number, tolerance?: number) {
        this.skuApw = weight;
        this.skuTolerance = tolerance !== undefined ? tolerance : weight / 10;
    }
}
