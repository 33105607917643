import React, { useContext, useEffect } from 'react';
import { FormattedMessage } from 'react-intl';

import { TerminalContext, TerminalContextInterface } from '../TerminalInit';
import { ContactGroup, isContactGroup } from '../api/contactGroups';
import { Contact, isContact } from '../api/contacts';
import { ProductInstance } from '../api/productInstances';
import { Product } from '../api/products';
import { Slot, SlotQueryStatus, useSlots } from '../api/slots';
import { SpotLayoutItem } from '../api/spotLayoutItems';
import { useSpotLayout } from '../api/spotLayouts';
import { useSpot } from '../api/spots';
import { TransactionType } from '../common/transactions';
import useSpotLayoutItemsForSlots from '../hooks/useSpotLayoutItemForSlot';
import BackAndHomeNavigationButtons from './common/BackAndHomeNavigationButtons';
import BaseView from './common/BaseView';
import ErrorView from './common/ErrorView';
import LoadingView from './common/LoadingView';
import SpotLayout from './elements/SpotLayout';

interface SlotAutoAssignOptions {
    products?: Product[] | null;
    productInstances?: ProductInstance[] | null;
}

interface SlotSelectViewProps {
    onSelect: (slot: Slot, autoAssigned: boolean) => void;
    onUnavailable: () => void;
    deliverySender?: ContactGroup | null;
    receiverContact?: Contact | ContactGroup | null;
    reservedSlot?: Slot | null;
    slotsFilter?: (slots: Slot[]) => Slot[];
    autoAssign?: SlotAutoAssignOptions | null;
    onHome?: () => void;
    onBack?: () => void;
    onInactivity?: () => void;
    product?: Product;
    currentSlotType?: number[];
    transactionType?: TransactionType;
}

const SlotSelectView = (props: SlotSelectViewProps) => {
    const { terminal } = useContext<TerminalContextInterface>(TerminalContext);
    const { data: spotLayout } = useSpotLayout(terminal?.spot_layout_url);
    const { data: spot } = useSpot(spotLayout?.spot_url);

    const receiver =
        props.receiverContact != null
            ? isContact(props.receiverContact)
                ? { receiver: props.receiverContact?.id.toString() }
                : { receiver_group: props.receiverContact?.id.toString() }
            : undefined;
    const availableSlotsResult = useSlots({
        spot: spot?.id,
        transaction_type: props.transactionType,
        status: SlotQueryStatus.AVAILABLE,
        ...receiver
    });

    const availableSlots = availableSlotsResult.data ? availableSlotsResult.data : [];
    const availableSlotsFiltered = props.slotsFilter ? props.slotsFilter(availableSlots) : availableSlots;
    const highlightedSlots = availableSlotsFiltered.concat(props.reservedSlot ? [props.reservedSlot] : []);
    const highlightedItems = useSpotLayoutItemsForSlots(highlightedSlots);

    useEffect(() => {
        if (availableSlotsResult.isSuccess && props.autoAssign && availableSlotsResult.data) {
            if (availableSlotsFiltered.length > 0) {
                props.onSelect(availableSlotsFiltered[0], true);
            } else if (availableSlotsFiltered.length === 0) {
                props.onUnavailable();
            }
        } else if (availableSlotsResult.isError) {
            props.onUnavailable();
        }
    }, [availableSlotsResult]);

    const backHomeNav = (
        <BackAndHomeNavigationButtons
            onHome={props.onHome}
            onBack={props.onBack}
        />
    );

    let deliverySenderName = '';
    let receiverName = '';
    if (props.deliverySender) {
        deliverySenderName = props.deliverySender.name;
    }
    if (props.receiverContact) {
        if (isContact(props.receiverContact)) {
            receiverName = `${props.receiverContact.first_name} ${props.receiverContact.last_name}`.trim();
        } else if (isContactGroup(props.receiverContact)) {
            receiverName = props.receiverContact.name;
        }
    }
    const deliverySenderInfo =
        deliverySenderName !== '' ? (
            <FormattedMessage
                id='views.SlotSelectView.fromSender'
                description='Displayed in transaction information overview on Slot Select view'
                defaultMessage='from {sender}'
                values={{
                    sender: <span className='font-weight-bold'>{deliverySenderName}</span>
                }}
            />
        ) : undefined;
    const receiverInfo =
        receiverName !== '' ? (
            <FormattedMessage
                id='views.SlotSelectView.toReceiver'
                description='Displayed in transaction information overview on Slot Select view'
                defaultMessage='to {receiver}'
                values={{
                    receiver: <span className='font-weight-bold'>{receiverName}</span>
                }}
            />
        ) : undefined;
    const transactionInformation =
        deliverySenderInfo || receiverInfo ? (
            <p className='text-center'>
                <FormattedMessage
                    id='views.SlotSelectView.transactionInfoMessage'
                    description='The entire transaction information message.'
                    defaultMessage='delivery {senderInfo} {receiverInfo}'
                    values={{
                        senderInfo: deliverySenderInfo,
                        receiverInfo: receiverInfo
                    }}
                />
            </p>
        ) : (
            <></>
        );

    let title: React.ReactNode;
    if (!(availableSlotsResult.isSuccess || availableSlotsResult.isError)) {
        title = (
            <FormattedMessage
                id='views.SlotSelectView.slotsResultIsLoading'
                description='This is the message displayed on the Slot Select view when the slot information is loading'
                defaultMessage='Searching for available SLOTS. Please wait.'
            />
        );
        return (
            <LoadingView
                title={title}
                onInactivity={props.onInactivity}
                onHome={props.onHome}
                onBack={props.onBack}
            />
        );
    } else if (availableSlotsResult.isSuccess) {
        if (availableSlotsFiltered.length > 0) {
            const availableStr = (
                <FormattedMessage
                    id='views.SlotSelectView.availableStatus'
                    description='The available slot status on the Slot Select view'
                    defaultMessage='available'
                />
            );
            title = (
                <FormattedMessage
                    id='views.SlotSelectView.slotsResultIsSuccess'
                    description='This is the message displayed on the Slot Select view when the slot information is successfully loaded'
                    defaultMessage='Select an {available} SLOT.'
                    values={{
                        available: <span className='font-weight-bold text-primary'>{availableStr}</span>
                    }}
                />
            );
        } else {
            title = (
                <FormattedMessage
                    id='views.SlotSelectView.slotsResultNoneAvailable'
                    description='This is the message displayed on the Slot Select view when no Slot is available'
                    defaultMessage='All possible SLOTS are occupied'
                />
            );
            return (
                <ErrorView
                    title={title}
                    onInactivity={props.onInactivity}
                    navbarItems={backHomeNav}
                />
            );
        }
    } else {
        title = (
            <FormattedMessage
                id='views.SlotSelectView.slotsResultError'
                description='This is the message displayed on the Slot Select view when an error occurred trying to determine available slots'
                defaultMessage='An error occurred trying to find an available SLOT'
            />
        );
        return (
            <ErrorView
                title={title}
                onInactivity={props.onInactivity}
                navbarItems={backHomeNav}
            />
        );
    }

    const onSelect = (item: SpotLayoutItem) => {
        const slot = highlightedSlots.find((slot) => {
            return slot.id === item.slot;
        });
        if (slot) {
            props.onSelect(slot, false);
        }
    };

    return (
        <BaseView
            onInactivity={props.onInactivity}
            navbarItems={backHomeNav}>
            <h3 className='mt-4 text-center'>{title}</h3>
            {transactionInformation}
            <SpotLayout
                onSelect={onSelect}
                enableZoom={true}
                highlightedItems={highlightedItems}
            />
        </BaseView>
    );
};

export default SlotSelectView;
