import { useContext } from 'react';

import { TerminalContext, TerminalContextInterface } from '../TerminalInit';
import { ContactGroup, useContactGroups } from '../api/contactGroups';
import { Contact, isContact, useContacts } from '../api/contacts';
import { ProductInstance } from '../api/productInstances';
import { Product, useProducts } from '../api/products';
import { useShops } from '../api/shops';
import { Slot, SlotForTransaction } from '../api/slots';
import { useSpotLayout } from '../api/spotLayouts';
import { useSpot } from '../api/spots';
import { TerminalWorkflowType } from '../api/terminals';
import { TransactionType } from '../common/transactions';
import { filterContactsByContactGroupId } from '../services/contact/ContactFilter';
import { isDelivery } from '../services/contact/GlobalPermissions';
import { filterContactGroupsByContact } from '../services/contactGroup/ContactGroupFilter';
import { partition } from '../services/global/arrays';
import {
    differentProductsAllowed,
    doesSlotHaveProductsAssigned,
    getEmptySlots,
    getNonFullSlots,
    getNonStockSlots,
    getPuDoSlots,
    getReturnSlots,
    getSlotsWithProductLinked,
    getSlotsWithTheSameProduct,
    getVendingSlots,
    hasReceiverGroupsTransaction,
    hasReceiverTransaction,
    isEmptySlot,
    isStockSlot
} from '../services/slot/SlotFilter';
import useTransactionsWithSlots from './useTransactionsWithSlots';

export interface FilterProps {
    authenticatedContact?: Contact;
    receiver?: Contact | ContactGroup;

    slots: Slot[];
    externalFilters?: [(s: Slot[]) => Slot[]];
    products?: Product[];
    productInstances?: ProductInstance[];
    isReturn?: boolean;
    slotType?: number[];
    transactionType?: TransactionType;
    isAutoAssign?: boolean;
}

const useSlotFilter = () => {
    const { terminal } = useContext<TerminalContextInterface>(TerminalContext);
    const { data: spotLayout } = useSpotLayout(terminal?.spot_layout_url);
    const { data: spot } = useSpot(spotLayout?.spot_url);
    const { data: contacts } = useContacts(undefined, { accountId: spot?.account });
    const { data: contactGroups } = useContactGroups();
    const { data: transactionSlotMap } = useTransactionsWithSlots({
        spotId: spot?.id
    });
    const { data: shops } = useShops({ terminal: terminal }, { enabled: !!terminal });
    const { data: products } = useProducts(shops && shops.length > 0 ? shops[0].products : undefined, undefined);

    const filter = (props: FilterProps): Slot[] => {
        let result: Slot[] = props.slots;
        if (props.externalFilters) {
            props.externalFilters.forEach((externalFilter) => {
                result = externalFilter(result);
            });
        }

        let relevantProducts: Product[] = [];

        switch (terminal?.workflow) {
            case TerminalWorkflowType.PUDO:
            case TerminalWorkflowType.PUDO_PRIVATE:
                if (isDelivery(props.authenticatedContact) && props.receiver) {
                    if (isContact(props.receiver)) {
                        result = sharedDropoffFilter_forContact(
                            result,
                            transactionSlotMap!,
                            contactGroups!,
                            contacts!,
                            spot!.sharing_allowed_for_contact_groups,
                            props.receiver
                        );
                    } else {
                        result = sharedDropoffFilter_forContactGroup(
                            result,
                            transactionSlotMap!,
                            contacts!,
                            spot!.sharing_allowed_for_contact_groups,
                            props.receiver
                        );
                    }
                    if (result.length >= 1) return result;
                }

                result = getPuDoSlots(result);
                if (transactionSlotMap !== undefined) result = getEmptySlots(result, transactionSlotMap);
                break;
            case TerminalWorkflowType.IMES_DEXIS_NIKE:
                //first make sure to have the relevant product(s)
                if (props.products) {
                    relevantProducts = props.products;
                } else if (props.productInstances && products) {
                    //currently only happens when checking the returns
                    relevantProducts = products.filter((p) => props.productInstances?.find((inst) => inst.product === p.id));
                }

                if (props.transactionType === TransactionType.RETURN || props.isReturn) return returnsFilter(result, transactionSlotMap!);
                if (props.transactionType === TransactionType.PUDO) return pudoFilterNike(result, transactionSlotMap!);
                if (props.transactionType === TransactionType.VENDING) return vendingFilter(props.slots, relevantProducts, transactionSlotMap!);
                break;
            case TerminalWorkflowType.LENDING:
                if (transactionSlotMap) {
                    result = canOnlyHouseOneTransactionFilter(result, transactionSlotMap);
                    if (props.slotType) {
                        result = filterBySlotType(result, props.slotType);
                    }
                }
                break;
        }

        return result;
    };

    return [filter] as const;
};

const filterBySlotType = (slots: Slot[], allowedSlotTypes: number[]): Slot[] => {
    return slots.filter((slot) => {
        if (allowedSlotTypes.includes(slot.specification.id)) {
            return true;
        }
        return false;
    });
};

const canOnlyHouseOneTransactionFilter = (slots: Slot[], slotsMap: Map<string, SlotForTransaction[]>): Slot[] => {
    return slots.filter((slot) => {
        if (!slotsMap.get(slot.id)) {
            return true;
        }
        return false;
    });
};

function pudoFilterNike(slots: Slot[], slotsMap: Map<string, SlotForTransaction[]>): Slot[] {
    //only get pudo slots
    const pudoSlots = getPuDoSlots(slots);
    //PUDO should only go into non stock
    const nonStockSlots = getNonStockSlots(pudoSlots);
    //get non full (if multiple pudos can go into the same slot this should also do a differentProduct check)
    const nonFullSLots = getNonFullSlots(nonStockSlots, slotsMap, TransactionType.PUDO);
    return nonFullSLots;
}

function vendingFilter(slots: Slot[], products: Product[], slotsMap: Map<string, SlotForTransaction[]>): Slot[] {
    //only get vending slots
    const vendingSlots = getVendingSlots(slots);
    //split stock and nonstock
    const [stockSlots, nonStockSlots] = partition(vendingSlots, (s: Slot) => isStockSlot(s));

    //split into slots with assigned products and non assigned
    const [linkedSlots, nonLinkedSlots] = partition(nonStockSlots, (s: Slot) => doesSlotHaveProductsAssigned(s));
    //split empty and non empty
    const [emptySlots, nonEmptySlots] = partition(nonLinkedSlots, (s: Slot) => isEmptySlot(s, slotsMap));
    //get those that are not full and split for differentproducts allowed
    const [sameProduct, differentProductAllowed] = partition(
        getNonFullSlots(nonEmptySlots, slotsMap, TransactionType.VENDING),
        (s: Slot) => !differentProductsAllowed(TransactionType.VENDING, s)
    );
    //those that need the same product check for same product
    const validSameProduct = getSlotsWithTheSameProduct(sameProduct, products, slotsMap);

    //get only valid assigned slots for product
    const validLinkedSlots = getSlotsWithProductLinked(linkedSlots, products[0]);
    //split into empty and non empty (these should have prio over all other slots)
    const [linkedEmptySlots, linkedNonEmptySlots] = partition(validLinkedSlots, (s: Slot) => isEmptySlot(s, slotsMap));

    //combine [linkedNonEmpty, linkedEmpty, same product, non empty, empty, stock] in that order
    return [...linkedNonEmptySlots, ...linkedEmptySlots, ...validSameProduct, ...differentProductAllowed, ...emptySlots, ...stockSlots];
}

function returnsFilter(slots: Slot[], slotsMap: Map<string, SlotForTransaction[]>): Slot[] {
    let result = slots;
    result = getNonStockSlots(result);
    result = getReturnSlots(result);
    const [emptySlots, nonEmptySlots] = partition(result, (s: Slot) => isEmptySlot(s, slotsMap));

    const validTransactionStatusSlots = getNonFullSlots(nonEmptySlots, slotsMap, TransactionType.RETURN).filter((slot) => {
        return (
            slotsMap.get(slot.id)?.filter((t) => t.transaction.type !== TransactionType.RETURN && t.transaction.type !== TransactionType.VENDING).length === 0
        );
    });

    return [...validTransactionStatusSlots, ...emptySlots];
}

function sharedDropoffFilter_forContact(
    slots: Slot[],
    slotsMap: Map<string, SlotForTransaction[]>,
    groups: ContactGroup[],
    contacts: Contact[],
    sharingAllowedGroups: number[],
    receiver: Contact
): Slot[] {
    const fullSlotsRemoved = getNonFullSlots(slots, slotsMap, TransactionType.PUDO);
    const [emptySlots, nonEmptySlots] = partition(fullSlotsRemoved, (s: Slot) => isEmptySlot(s, slotsMap));
    const [contactSlots, nonContactSlots] = partition(nonEmptySlots, (s: Slot) => hasReceiverTransaction(s, slotsMap, receiver));
    const receiverGroups = filterContactGroupsByContact(groups, receiver).filter((g) => sharingAllowedGroups.includes(g.id));
    // does not allow contacts to be part of multiple sharing groups
    const contactGroupSlots: Slot[] = [];
    receiverGroups.forEach((rg) =>
        sharedDropoffFilter_forContactGroup(nonContactSlots, slotsMap, contacts, sharingAllowedGroups, rg).forEach((s) => contactGroupSlots.push(s))
    );

    return [...contactSlots, ...contactGroupSlots, ...emptySlots];
}
function sharedDropoffFilter_forContactGroup(
    slots: Slot[],
    slotsMap: Map<string, SlotForTransaction[]>,
    contacts: Contact[],
    sharingAllowedGroups: number[],
    receiver: ContactGroup
): Slot[] {
    const fullSlotsRemoved = getNonFullSlots(slots, slotsMap, TransactionType.PUDO);
    const [emptySlots, nonEmptySlots] = partition(fullSlotsRemoved, (s: Slot) => isEmptySlot(s, slotsMap));
    const [contactGroupSlots, nonContactGroupSlots] = partition(nonEmptySlots, (s: Slot) => hasReceiverGroupsTransaction(s, slotsMap, receiver));

    if (sharingAllowedGroups.includes(receiver.id)) {
        const sharingContacts = filterContactsByContactGroupId(contacts, receiver.id);
        const slotsFromSharingGroupMembers = nonContactGroupSlots.filter((s: Slot) => sharingContacts.find((c) => hasReceiverTransaction(s, slotsMap, c)));
        return [...contactGroupSlots, ...slotsFromSharingGroupMembers, ...emptySlots];
    } else {
        return [...contactGroupSlots, ...emptySlots];
    }
}

export default useSlotFilter;
