import { Modal, Spinner } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';

import { SpotLayoutItemProductAmount } from '../WarehouseWorkflow';

interface Props {
    modalInfo:
        | {
              valid: SpotLayoutItemProductAmount[];
              other: SpotLayoutItemProductAmount[];
          }
        | undefined;
    show: boolean;
    handleClose: (value: boolean) => void;
}

export default function CheckoutConfirmationModal(props: Props) {
    return (
        <Modal
            centered
            animation={false}
            show={props.show}
            onHide={() => props.handleClose(false)}>
            {props.modalInfo && props.modalInfo?.other.length > 0 ? (
                <>
                    <Modal.Body>
                        <p className='text-center'>
                            <FormattedMessage
                                id='WarehouseWorkflow.checkout.modal.SomeoneInsideMessage'
                                description='The message that is shown in the modal on checkout when there are some untracked transactions.'
                                defaultMessage='There are some products missing that you did not select. Is there someone else inside?'
                            />
                        </p>
                    </Modal.Body>
                    <Modal.Footer className='border-0 justify-content-center'>
                        <div className='d-flex flex-row justify-content-between align-items-center w-100'>
                            <button
                                className='secondary-button btn-lg'
                                onClick={() => props.handleClose(false)}>
                                <FormattedMessage
                                    id='WarehouseWorkflow.checkout.modal.no'
                                    description='The button indicating there is no one inside.'
                                    defaultMessage='No'
                                />
                            </button>
                            <button
                                className='primary-button btn-lg'
                                onClick={() => props.handleClose(true)}>
                                <FormattedMessage
                                    id='WarehouseWorkflow.checkout.modal.yes'
                                    description='The button indicating there is someone inside.'
                                    defaultMessage='Yes'
                                />
                            </button>
                        </div>
                    </Modal.Footer>
                </>
            ) : (
                <>
                    <Modal.Body>
                        <div className='d-flex flex-column align-items-center'>
                            <Spinner
                                animation='border'
                                role='status'
                            />
                        </div>
                    </Modal.Body>
                </>
            )}
        </Modal>
    );
}
